import { render, staticRenderFns } from "./upkeep.vue?vue&type=template&id=dbfa423e&scoped=true&"
import script from "./upkeep.vue?vue&type=script&lang=js&"
export * from "./upkeep.vue?vue&type=script&lang=js&"
import style0 from "./upkeep.vue?vue&type=style&index=0&id=dbfa423e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbfa423e",
  null
  
)

export default component.exports