<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :model="formAdd" :rules="ruleCar">
      <el-form-item label="车牌" :label-width="formLabelWidth" prop="carId">
        <el-select
            v-model="formAdd.carId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入车牌关键字"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="pickCarNum"
            v-if="disabled== false"
        >
          <el-option
              v-for="item in carList"
              :key="item.id"
              :label="item.carNum"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input v-model="formAdd.carNum" autocomplete="off" placeholder="车牌信息"   maxlength="6"  :disabled="disabled" v-if="disabled== true"></el-input>
      </el-form-item>
      <el-form-item label="负责人" :label-width="formLabelWidth" prop="chargeId">
        <el-select v-model="formAdd.chargeId" placeholder="请选择负责人" filterable >
          <el-option
              v-for="item in hList"
              :key="item.id"
              :label="item.realName"
              :value="item.id"
              @change="pickCharger"

          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="进厂日期" :label-width="formLabelWidth" prop="intoTime">
        <el-date-picker
            v-model="formAdd.intoTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择年检时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="出厂日期" :label-width="formLabelWidth" prop="outTime">
        <el-date-picker
            v-model="formAdd.outTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择出厂时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="维修金额" :label-width="formLabelWidth" prop="repairAmount">
        <el-input v-model="formAdd.repairAmount" autocomplete="off" placeholder="请输入维修金额"  type="number" maxlength="6"></el-input>
      </el-form-item>
      <el-form-item label="当前公里数" :label-width="formLabelWidth" prop="currentMiles">
        <el-input v-model="formAdd.currentMiles" autocomplete="off" placeholder="请输入公里数"  type="number" maxlength="9"></el-input>
      </el-form-item>
      <el-form-item label="下次保养日期" :label-width="formLabelWidth" prop="nextMaintainTime">
        <el-date-picker
            v-model="formAdd.nextMaintainTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择出厂时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="下次保养公里数" :label-width="formLabelWidth" prop="nextMiles">
        <el-input v-model="formAdd.nextMiles" autocomplete="off" placeholder="请输入下次保养公里数"  type="number" maxlength="9"></el-input>
      </el-form-item>
      <el-form-item label="结算状态" :label-width="formLabelWidth" prop="settlementStatus">
        <el-radio-group v-model="formAdd.settlementStatus">
          <el-radio-button :label="1">未结算</el-radio-button>
          <el-radio-button :label="2">已结算</el-radio-button>
          <el-radio-button :label="3">已确认</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="提车状态" :label-width="formLabelWidth" prop="pickCarStatus">
        <el-radio-group v-model="formAdd.pickCarStatus">
          <el-radio-button :label="1">未提车</el-radio-button>
          <el-radio-button :label="2">已提车</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="保养内容" :label-width="formLabelWidth" prop="remark">
        <el-input v-model="formAdd.remark" autocomplete="off" placeholder="请输入~"
                  type="textarea"
                  rows="6"
                  maxlength="200"
                  show-word-limit
                  resize="none"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getCarList, getResponsible} from "@/utils/api/dict";
import {detailKeep} from "@/utils/api/car";
export default {
  name: "EditUpkeep",
  data(){
    return{
      edit:0,//0 新增 ，1编辑
      dialogFormVisible:false,
      dialogFormVisible2:false,
      title:'',
      formLabelWidth:"130px",
      labelPosition:"right",
      disabled:false,
      hList:[],
      carList:[],
      value: [],
      list: [],
      loading: false,
      formAdd:{
        carId:"",//车牌
        carNum:"",//车牌
        chargeId:"",//负责人
        chargeName:"",//负责人
        intoTime:"",//进厂时间
        outTime:"",//出厂日期
        repairAmount:"",//维修金额
        currentMiles:"",//当前公里数
        nextMaintainTime:"",//下次保养时间
        nextMiles:"",//下次保养公里数
        settlementStatus:1,//结算状态
        pickCarStatus:1,//提车状态
        remark:"",//保养内容
      },
      ruleCar:{
        carId: [
          { required: true, message: "请选择车辆信息", trigger: 'blur' },
        ],
        chargeId: [
          { required: true, message: "请选择负责人", trigger: 'blur' },
        ],
        intoTime: [
          { required: true, message: "请选择进厂时间", trigger: 'blur' },
        ],
        outTime: [
          { required: true, message: "请选择出厂时间", trigger: 'blur' },
        ],
        repairAmount: [
          { required: true, message: "请输入维修金额", trigger: 'blur' },
        ],
        currentMiles: [
          { required: true, message: "请输入当前已行驶公里数", trigger: 'blur' },
        ],
        nextMaintainTime: [
          { required: true, message: "请选择下次保养时间", trigger: 'blur' },
        ],
        nextMiles: [
          { required: true, message: "请输入下次保养的公里数", trigger: 'blur' },
        ],
        settlementStatus: [
          { required: true, message: "请选择结算状态", trigger: 'blur' },
        ],
        pickCarStatus: [
          { required: true, message: "请选择提车状态", trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    this.getResponsible()
  },
  methods:{
    showEdit(row) {
      if (!row) {
        this.title = "新增维修记录";
        this.edit=0;
        this.dialogFormVisible = true;
      } else {
        this.title = "编辑维修记录";
        this.edit=1;
        this.getDetail(row.maintainId)
      }

    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          if (this.edit==0){
            this.$emit('add',this.formAdd)
          }else{
            this.$emit('edit',this.formAdd)
          }
          this.dialogFormVisible = false
          this.$refs['form'].resetFields()
          this.form = this.$options.data().formAdd
        } else {
          return false
        }
      })
    },


    pickCarNum(cid){
      let obj = {};
      obj =  this.carList.find((item)=>{//这里的userRoleList就是上面遍历的数据源
        return item.id == cid;//筛选出匹配数据
      });
      this.formAdd.carNum = obj.carNum;
    },
    pickCharger(id){
      let obj = {};
      obj =  this.carList.find((item)=>{//这里的userRoleList就是上面遍历的数据源
        return item.id == id;//筛选出匹配数据
      });
      this.formAdd.chargeName = obj.realName;
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.getCarList(query)
        }, 200);
      } else {
        this.carList = [];
      }
    },
    //获取负责人
    getResponsible(){
      getResponsible().then(res => {
        if(res.code === 0){
          this.hList = res.data
        }
      })
    },
    getCarList(key){
      let form={
        carNum:key
      }
      getCarList(form).then(res=>{
        this.carList = res.data
      })
    },
    getDetail(id){
      this.getCarList()
      let form={
        id:id
      }
      detailKeep(form).then(res=>{
        this.disabled= true
        this.formAdd= Object.assign({}, this.formAdd, res.data);
        this.dialogFormVisible= true
      })
    }
  }
}
</script>

<style scoped >
.el-select{
  width: 100%;

}


</style>
